import { TextAreaProps } from './types';
import { useStyles } from './styles';
import { Column, ContentEditable, Row, Typography } from 'components/ui/atoms';
import { default as FormHelperError } from 'shared/components/FormHelperError';
import { useRef, type ChangeEvent } from 'react';
import { generateClassname } from 'helpers/helpers';

export const TEXT_AREA_HINT = 'text-area-hint';

const TextArea = ({
  text,
  onChange,
  errorLabel,
  hintLabel,
  placeholder,
  className,
  maxLength,
  disabled = false,
  allowHtml = false,
  contained = false,
  sanitizeInput = false,
}: TextAreaProps) => {
  const textAreaIdRef = useRef(generateClassname());
  const classes = useStyles({ contained, hasError: !!errorLabel, isDisabled: disabled });

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const input = event.target;

    if (sanitizeInput) {
      const cursorPosition = input.selectionStart;

      const cleanedValue = input.value.replace(/[^a-zA-Z0-9\s&*():;'",.!\?\{\}@-]/g, '');

      if (input.value !== cleanedValue) {
        input.value = cleanedValue;
        input.setSelectionRange(cursorPosition, cursorPosition - 1);
      }
    }

    onChange(input.value);
  };

  return (
    <Column gap={6} css={classes.root} inline contained={contained}>
      <Column gap={8} contained={contained}>
        {allowHtml ? (
          <ContentEditable
            id={textAreaIdRef.current}
            css={classes.input}
            className={className}
            text={text}
            onChange={onChange}
            allowHtml={allowHtml}
            placeholder={placeholder}
            maxLength={maxLength}
            disabled={disabled}
          />
        ) : (
          <textarea
            id={textAreaIdRef.current}
            css={classes.input}
            value={text}
            onChange={(e) => handleChange(e)}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
        {typeof maxLength === 'number' && (
          <Row right>
            <Typography variant="supporting.helper">
              {text.length}/{maxLength}
            </Typography>
          </Row>
        )}
      </Column>

      {hintLabel && !errorLabel && (
        <Row gap={8}>
          <Typography variant="supporting.helper" className={TEXT_AREA_HINT}>
            {hintLabel}
          </Typography>
        </Row>
      )}

      {errorLabel && <FormHelperError id={textAreaIdRef.current} message={errorLabel} />}
    </Column>
  );
};

export default TextArea;
